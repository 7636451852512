<template>
  <div>
    <div class="momey">
      <div class="bold" style="display: flex;align-items: center;" @click="showTips = true">
        企业团检金
        <img class="icon-tips" src="@/assets/icon-tips.svg" alt="" />
      </div>
      <div><span class="count">{{ amount }}</span>元</div>
    </div>

    <van-popup
      class="tips-popup"
      v-model="showTips"
      get-container="body"
      position="center"
    >
      <div class="tips-title">企业团检金使用须知</div>
      <div class="tips-desc">
        1.企业团检金仅用于推荐列表的套餐；<br />
        2.如选择加项，企业团检金可抵扣加项费用，如超出的费用则需自费；下单后账户剩余的企业团检金将作废。<br />
        3.在有效期内取消订单，订单费用原路返回企业团检金。
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    amount: {
      type: Number,
    },
  },
  data() {
    return {
      showTips: false,
    };
  },
};
</script>

<style scoped>
.momey {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 10px;
  height: 40px;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #475266;
}

.icon-tips {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.count {
  margin-right: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #2c2f37;
}

.tips-popup {
  padding: 20px;
  border-radius: 10px;
}

.tips-title {
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
  line-height: 20px;
  text-align: center;
}

.tips-desc {
  margin-top: 15px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  line-height: 24px;
}
</style>
<template>
  <van-popup
    class="van-popup"
    v-model="show"
    position="right"
    closeable
    close-on-popstate
    @close="onClose"
  >
    <div class="popup">
      <div>
        <div class="user-info van-hairline--bottom">
          <div class="sub-title">已登录账号</div>
          <div class="user-name">{{ userInfo.phone }}</div>
        </div>
        <div
          v-if="userInfo.personType === 1"
          class="item van-hairline--bottom"
          @click="$router.push('/group-contact-list')"
        >
          <div class="item-right">
            <img class="item-icon" src="@/assets/icon-kinship.png" alt="" />
            <span>我的家属</span>
          </div>
          <van-icon name="arrow" color="#ccc" />
        </div>

        <div
          class="item van-hairline--bottom"
          @click="
            $router.push('/person-center?hospitalId=' + $store.state.hospitalId)
          "
        >
          <div class="item-right">
            <img class="item-icon" src="@/assets/icon-group-user.png" alt="" />
            <span>个人中心</span>
          </div>
          <van-icon name="arrow" color="#ccc" />
        </div>
      </div>
      <div class="item van-hairline--top" @click="onLogout">
        <div class="item-right">
          <img class="item-icon" src="@/assets/icon-logout.png" alt="" />
          <span>切换登录</span>
        </div>
        <van-icon name="arrow" color="#ccc" />
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    showPopup(val) {
      this.show = val;
    },
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    onLogout() {
      this.$emit("onLogout");
    },
  },
};
</script>

<style scoped>
.van-popup {
  max-height: 100vh;
  height: 100%;
}
.popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
  width: 320px;
  height: 100%;
}

.user-info {
  position: relative;
  padding: 0 20px;
}
.sub-title {
  font-size: 14px;
  color: #878d99;
}

.user-name {
  margin-top: 10px;
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #2c2f37;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
  font-size: 16px;
  color: #475266;
}

.item-right {
  display: flex;
  align-items: center;
}

.item-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
</style>
<template>
  <div>
    <div class="user-info">
      <div class="logout-area" @click="showSetting = true">
        <img class="icon-logout" src="@/assets/icon-group-logout.png" alt="" />
        <span>设置{{ userInfo.personType === 1 ? "亲属体检" : "" }}</span>
      </div>
      <div class="user-header">
        <img class="avatar" :src="userInfo.hospitalLogo" alt="" />
        <div>
          <div class="user-name">
            {{ userInfo.name }}
            <span class="user-flag" v-if="userInfo.personType === 2">亲属</span>
          </div>
          <div class="phone">{{ userInfo.phone }}</div>
        </div>
      </div>
      <div class="company">所在公司：{{ userInfo.enterpriseName }}</div>
      <div class="date">有效日期：{{ userInfo.endtime }}</div>
      <div class="tips" v-if="userInfo.personType === 1">
        如需亲属体检，请点击右上角设置亲属按钮，点击我的亲属进行维护亲属相关信息。
      </div>
    </div>

    <div class="main">
      <tips
        v-if="userInfo.examMethod === 2"
        :amount="userInfo.remainAmount"
      ></tips>

      <van-empty
        v-if="showEmpty"
        description="查询不到团检套餐，请联系企业确认您是否在本期体检名单中或者拨打400-9003732转2转0咨询"
      />
      <template v-else>
        <list
          :productList="productList"
          :usedProductId="userInfo.usedProductId"
          :userInfo="userInfo"
        ></list>
      </template>
    </div>

    <logout :show="showLogout" @onClose="showLogout = false"></logout>
    <setting
      :userInfo="userInfo"
      :showPopup="showSetting"
      @onClose="showSetting = false"
      @onLogout="onLogout"
    ></setting>
  </div>
</template>

<script>
import tips from "@/views/components/group-profile/tips.vue";
import list from "@/views/components/group-profile/list.vue";
import logout from "@/views/components/logout.vue";
import setting from "@/views/components/group-profile/setting.vue";
import { getBindingGroupInfo, getGroupProducts } from "@/api";
export default {
  components: {
    tips,
    list,
    logout,
    setting,
  },
  data() {
    return {
      showTips: false,
      userInfo: {},
      productList: [],
      showEmpty: false,
      showLogout: false,
      showSetting: false,
    };
  },
  created() {
    if (localStorage.getItem("hospitalId") != this.$route.query.hospitalId) {
      localStorage.setItem("token", "");
      localStorage.setItem("telNo", "");
      localStorage.removeItem("hospitalInfo");
    }
    this.$store.commit(
      "setHospitalId",
      this.$route.query.hospitalId || localStorage.getItem("hospitalId")
    );
    this.getUserInfo();
  },
  mounted() {
    this.$setTitle(this.$store.state.hospitalInfo.displayName || "单位体检");
  },
  methods: {
    async getUserInfo() {
      const res = await getBindingGroupInfo();
      if (res.code === 401 || !res.data) {
        this.$router.replace("/group-login");
      }
      if (res.success) {
        this.userInfo = res.data;
        this.getProducts();
      }
    },
    async getProducts() {
      const res = await getGroupProducts();
      if (res.success) {
        if (res?.data?.length) {
          this.productList = res.data;
        } else {
          this.showEmpty = true;
        }
      }
    },
    onLogout() {
      this.showSetting = false;
      this.showLogout = true;
    },
  },
};
</script>


<style>
html body {
  background-color: #f4f4f4;
}
.van-empty__description {
  text-align: center;
}
</style>
<style scoped>
.user-info {
  position: relative;
  padding: 25px 20px 16px 20px;
  background-color: #fff;
  background: url("../../assets/group-profile-bg.png") 100% 100% no-repeat;
  background-size: 100% 100%;
}

.logout-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  margin: 25px 15px;
  font-size: 13px;
  color: #878d99;
}

.icon-logout {
  margin-bottom: 3px;
  width: 24px;
  height: 24px;
}

.user-header {
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: 10px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
}

.user-name {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
}

.user-flag {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: bold;
  color: #ee7c3c;
  width: 50px;
  height: 20px;
  line-height: 1;
  border-radius: 10px;
  border: 1px solid #ee7c3c;
}

.phone {
  margin-top: 5px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
}

.company,
.date {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #475266;
}

.tips {
  margin-top: 5px;
  color: #ffb470;
}

.company {
  margin-top: 15px;
}
.date {
  margin-top: 5px;
}

.main {
  padding: 0 10px;
}

.recommend-button-container {
  position: relative;
  height: calc(80px + constant(safe-area-inset-bottom));
  height: calc(80px + env(safe-area-inset-bottom));
}

.recommend-button-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: #fff;
  padding: 10px;
  padding-bottom: calc(10px + constant(safe-area-inset-bottom));
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
}

.recommend-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: #00c4b3;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.icon-star {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}
</style>
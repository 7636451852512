<template>
  <van-popup
    v-model="show"
    class="popup"
    position="center"
    round
    @close="onClose"
  >
    <div class="header">切换登录</div>
    <div class="sub-desc">您确定要切换并退出当前账号吗？</div>
    <van-button type="primary" class="confirm" @click="logout">确定</van-button>
    <div class="cancel" @click="onClose">取消</div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    logout() {
      this.$store.commit("setToken", "");
      this.$store.commit("setEnterpriseCode", "");
      localStorage.removeItem("groupList");
      this.onClose();
      this.$router.replace("/group-login");
    },
  },
};
</script>

<style scoped>
.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}
.header {
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
}

.sub-desc {
  margin-top: 10px;
  font-size: 14px;
  color: #878d99;
}

.confirm {
  margin-top: 20px;
  width: 150px;
  height: 40px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
}

.cancel {
  margin-top: 10px;
  font-size: 14px;
  color: #878d99;
}
</style>